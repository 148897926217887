.shimmer-wrapper{
  //width: 0;
  //animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.shimmer {
  animation : shimmer 2s infinite;
  background: linear-gradient(to right,
    rgba(239, 241, 243, 1.0) 0%,
    rgba(226, 226, 226, 1.0) 6%,
    rgba(239, 241, 243, 1.0) 12%
  );
  background-size: 100vw 100%;
  border-radius: 4px;
  &.shimmer--dark{
    background-image: linear-gradient(to right,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 6%,
        rgba(255, 255, 255, 0.1) 12%
    );
  }
}


@keyframes shimmer {
  0% {
    background-position: -15vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
}
