@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~ngx-toastr/toastr";

@import "./styles/shimmer";

// Fonts
@font-face {
  font-family: "Metropolis";
  font-weight: 300;
  src: url("/assets/fonts/Metropolis-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  font-weight: 400;
  src: url("/assets/fonts/Metropolis-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  font-weight: 500;
  src: url("/assets/fonts/Metropolis-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  font-weight: 600;
  src: url("/assets/fonts/Metropolis-SemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  src: url("/assets/fonts/Metropolis-Bold.otf");
  font-display: swap;
}

@mixin for-phone-only {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (max-width: 900px) {
    @content;
  }
}

html * {
  font-family: Metropolis !important;
}

body {
  background-color: white;
}

.fill-text {
  background-image: url(assets/images/fill-text-sm.jpg);
  @screen md {
    background-image: url(assets/images/fill-text.jpg);
  }
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.header {
  background-image: url(assets/images/brand-pattern.png);
  background-color: #2b2a35;
  background-size: 100%;
  background-size: cover;
}

.primary-button {
  @apply transition duration-500 h-[48px] border-[2px] border-primary-brand rounded-full hover:bg-primary-brand hover:text-white text-[18px] font-semibold hover:shadow-2xl;
}
